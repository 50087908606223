@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --max-width: 1100px;
  --border-radius: 12px;

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
  --primary-color: #7413BD;

  --font-size-12: clamp(12px, 1vw, 12px);
  --font-size-13: clamp(13px, 1vw, 13px);
  --font-size-15: clamp(14px, 1vw, 15px);
  --font-size-14: clamp(14px, 1vw, 14px);
  --font-size-16: clamp(14px, 1vw, 16px);
  --font-size-17: clamp(14px, 1vw, 17px);
  --font-size-18: clamp(14px, 1vw, 18px);
  --font-size-19: clamp(14px, 1vw, 19px);
  --font-size-20: clamp(14px, 1vw, 20px);
  --font-size-21: clamp(14px, 1vw, 21px);
  --font-size-22: clamp(14px, 1vw, 22px);
  --font-size-23: clamp(14px, 1vw, 23px);
  --font-size-24: clamp(16px, calc(2vw + 1rem), 24px);
  --font-size-25: clamp(16px, calc(2vw + 1rem), 25px);
  --font-size-26: clamp(17px, calc(2vw + 1rem), 26px);
  --font-size-27: clamp(18px, calc(2vw + 1rem), 27px);
  --font-size-28: clamp(18px, calc(2vw + 1rem), 28px);
  --font-size-29: clamp(19px, calc(2vw + 1rem), 29px);
  --font-size-30: clamp(20px, calc(2vw + 1rem), 30px);
  --font-size-31: clamp(20px, calc(2vw + 1rem), 31px);
  --font-size-32: clamp(21px, calc(2vw + 1rem), 32px);
  --font-size-33: clamp(22px, calc(2vw + 1rem), 33px);
  --font-size-34: clamp(22px, calc(2vw + 1rem), 34px);
  --font-size-35: clamp(23px, calc(2vw + 1rem), 35px);
  --font-size-36: clamp(24px, calc(2vw + 1rem), 36px);
  --font-size-37: clamp(24px, calc(2vw + 1rem), 37px);
  --font-size-38: clamp(25px, calc(2vw + 1rem), 38px);
  --font-size-39: clamp(26px, calc(2vw + 1rem), 39px);
  --font-size-40: clamp(26px, calc(2vw + 1rem), 40px);
  --font-size-41: clamp(27px, calc(2vw + 1rem), 41px);
  --font-size-42: clamp(28px, calc(2vw + 1rem), 42px);
  --font-size-43: clamp(28px, calc(2vw + 1rem), 43px);
  --font-size-44: clamp(29px, calc(2vw + 1rem), 44px);
  --font-size-45: clamp(30px, calc(2vw + 1rem), 45px);
  --font-size-46: clamp(30px, calc(2vw + 1rem), 46px);
  --font-size-47: clamp(31px, calc(2vw + 1rem), 47px);
  --font-size-48: clamp(32px, calc(2vw + 1rem), 48px);
  --font-size-49: clamp(32px, calc(2vw + 1rem), 49px);
  --font-size-50: clamp(33px, calc(2vw + 1rem), 50px);


  /*================================== font definitons =========================================*/

  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-24: 24px;
  --font-size-36: 36px;

  --font-weight-bold: 700;
  --font-weight-medium: 500;
  --font-weight-regular: 400;

  /*================================== font definitons end=========================================*/
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Mulish" !important;
  white-space: pre-wrap;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Mulish";
}

body {
  font-family: "Mulish";
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

b {
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  white-space: pre;
}

@media only screen and (max-width:1000px) {
  :root {
    --font-size-11: 10px;
    --font-size-12: 11px;
    --font-size-13: 12px;
    --font-size-14: 13px;
    --font-size-15: 14px;
    --font-size-16: 15px;
    --font-size-17: 16px;
    --font-size-18: 17px;
    --font-size-20: 19px;
    --font-size-22: 21px;
    --font-size-24: 22px;
    --font-size-36: 30px;
  }
}

@media only screen and (max-width:450px) {
  :root {}
}

/* ========================================= globally defined classnames ========================================= */

.global-page-header {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.global-page-header-2 {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-bold);
}

.global-title-subheader {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.global-subheader {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-medium);
  text-transform: capitalize;
}

.global-button-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-bold);
  text-transform: capitalize;
}

.global-button-text-regular {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
}

.global-table-header-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-bold);
}

.global-table-other-text {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
}

.global-label-text {
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-regular);
}

.global-body-text {
  font-size: var(--font-size-15);
  font-weight: var(--font-weight-regular);
  white-space: pre-wrap;
}

.global-card-header {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-bold);
}

.global-subheader-regular {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-regular);
  text-transform: capitalize;
}

.global-body-medium {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-medium);
}

.ant-picker .ant-picker-input>input:placeholder-shown {
  font-size: var(--font-size-16);
}

.ant-select-lg.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: var(--font-size-16);

}

.ant-select-lg.ant-select-show-arrow .ant-select-selection-item {
  font-size: var(--font-size-16);
}

.ant-picker-large .ant-picker-input>input {
  font-size: var(--font-size-16);
}

.ant-input-lg {
  font-size: 16px !important;
}

.ant-picker-input>input {
  font-size: 16px !important;
}

.req-label {
  color: #5B5B5B;
  margin-bottom: 12px;
}

.global-display-flex-space-between-important {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.ant-drawer {
  z-index: 2000 !important;
}

/* ========================================= globally defined classnames end ========================================= */

input::placeholder,
.ant-select-selection-placeholder {
  font-size: var(--font-size-16) !important;
}

.ant-input {
  font-weight: 300 !important;
  font-size: var(--font-size-16) !important;
}

.ant-select-selection-item,
.ant-picker-input input,
.ant-select-item-option {
  font-weight: 300 !important;
  font-size: var(--font-size-16) !important;
}

.languageSelect .ant-select-selection-item {
  font-weight: 400 !important;
  font-size: var(--font-size-14) !important;
}

:where(.ant-modal) {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
}

@media only screen and (max-width:620px) {
  .ant-picker-panels {
    flex-direction: column !important;
    max-height: 370px !important;
    overflow-y: auto !important;
  }
}
body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #485473;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body>h1 {
    margin-top: 0px;
    margin-bottom: 12px;
    font-size: var(--font-size-22);
    font-weight: 500
}

.react-confirm-alert-body>h3 {
    margin: 0;
    font-size: var(--font-size-16);
}

.react-confirm-alert-button-group {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group>button {
    outline: none;
    background: #7413BD;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #eee;
    margin-right: 10px;
    border-radius: 5px;
    font-size: var(--font-size-12);
    cursor: pointer;
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
